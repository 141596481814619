<template>
  <div class="chat_wrapper">
    <div v-if="room_info.roomId" class="chat_box">
      <div ref="chat_content" class="chat_content">
        <div
          v-for="(item, index) in message_list"
          :key="index"
          class="message_item"
          :class="{
            role_people: item.sendRole === 0,
            role_anchor: item.sendRole === 10,
            role_me: item.fromUserId === users.userId,
          }"
        >
          <van-loading
            v-if="item.fromUserId === users.userId && !item.sendSuccess"
            class="msg_status"
            size="20"
            color="#F36218"
          />

          <van-icon
            v-if="item.sendSuccess === 2"
            class="msg_status"
            size="20"
            color="#f00"
            name="warning"
            @click="send(item)"
          />
          <div class="news_item">
            <p class="user_info">
              <span class="name">
                {{
                  item.fromUserId === users.userId
                    ? "我"
                    : item.sendRole === 10
                    ? "主播"
                    : `游客${item.fromUserId.slice(0, 8)}`
                }}
              </span>
              <span class="datetime">{{ item.sendTime }}</span>
            </p>
            <p class="user_reply">
              {{ item.msgContent.msg }}
            </p>
          </div>
        </div>
      </div>
      <div class="input_wrapper">
        <!-- <input v-model="replyContent"
                       type="text"
                       :disabled="banned"
                       class="reply_input"
                       placeholder="请输入"> -->
        <van-field
          v-model="replyContent"
          class="reply_input"
          :disabled="banned"
          maxlength="200"
          placeholder="请输入"
        />

        <i
          :class="['btn_reply', replyContent.length > 0 && 'btn_reply_inputed']"
          @click="send('')"
        />
      </div>
    </div>
    <div v-else class="chat_none">暂无内容</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Field, Toast, Loading, Icon } from "vant";
import Chat from "@med/mchat";
import Config from "@/config";
// import Chat from '@/utils/chat';
import { dateFormat } from "../../utils";

export default {
  components: {
    vanField: Field,
    vanLoading: Loading,
    vanIcon: Icon,
  },
  data() {
    return {
      init: false,
      message_list: [],
      replyContent: "",
      banned: false,
      message: {},
      msgId: 1,
    };
  },
  computed: mapState({
    room_info: (state) => state.live.room_info,
    users: (state) => state.users.info,
  }),
  watch: {
    "room_info.roomId": {
      handler(val) {
        if (val && !this.init && this.users.userId) {
          this.init = true;
          this.init_chat();
        }
        if (!val) {
          // 如果没有roomid 说明没有直播，则直接认为聊天已经创建
          this.$store.commit("save_client", true);
        }
      },
    },

    "users.userId": {
      handler(val) {
        if (val && !this.init && this.room_info.roomId) {
          this.init = true;
          this.init_chat();
        }
      },
      immediate: true,
    },
    // replyContent(val) {
    //     if (val.length > 200) {
    //         this.replyContent = this.replyContent.substring(0, 200);
    //     }
    // },
  },
  activated() {
    this.init_socrll();
  },
  beforeDestroy() {
    this.$store.commit("set_room_info", null);
    this.closeChat();
  },
  methods: {
    init_chat() {
      this.chat = new Chat({
        debug: true,
        url: `${Config.wsUrl}/live/${this.users.userId}?roomid=${this.room_info.roomId}`,
        onMessage: (res) => {
          const data = JSON.parse(res);
          data.data.sendTime = dateFormat(
            data.data.sendTime,
            "yyyy-MM-dd hh:mm:ss"
          );
          const type = data.data.msgType;
          switch (type) {
            // case 211:
            //     console.log(data);
            //     break;
            case 601: // 房间人数
            case 602:
              this.$store.commit(
                "change_person_count",
                data.data.msgContent.msg
              );
              break;
            case 610: // 收到文本消息
              // eslint-disable-next-line no-plusplus
              for (let i = this.message_list.length - 1; i >= 0; i--) {
                const item = this.message_list[i];
                if (item.msgContent.id === data.data.msgContent.id) {
                  this.message_list[i].sendSuccess = 1;
                }
              }

              if (data.data.fromUserId !== this.users.userId) {
                this.message_list.push(data.data);
              }

              break;
            case 611: // 正在禁言中不能发送成功
              Toast.fail("抱歉消息发送失败");
              break;

            case 604: // 直播结束
              this.closeChat();
              break;
            // case 606: // 被禁言
            // case 607: // 解除禁言
            case 605: // 被踢出房间
              Toast.fail("观看人数太多了，请稍后进入");
              this.$router.push({
                path: "/",
                query: this.$route.query,
              });
              break;
            default:
              console.log(data);
          }
        },
      });
    },
    send(message) {
      if (!this.replyContent && !message) {
        console.log("----");
        return;
      }
      window.statisticsPoint({
        pageId: this.$route.meta.pageId,
        eventType: "click",
        buttonId: "B0006",
      });

      // eslint-disable-next-line camelcase
      const {
        room_info,
        users: { userId },
      } = this;

      const msg = message || {
        chatRoomId: room_info.roomId,
        orderId: `${room_info.liveRecordId}`,
        fromUserId: userId,
        sendRole: 0,
        receiveRole: 10,
        msgType: 610,
        sendSuccess: 0,
        msgContent: {
          id: this.msgId,
          msg: this.replyContent,
        },
      };
      console.log("----======", msg);
      this.chat.sendMsg(msg);

      if (!message) {
        console.log("????");
        this.message_list.push(msg);
        this.msgId += 1;
        this.replyContent = "";
      } else {
        msg.sendSuccess = 0;
      }

      this.$nextTick(() => {
        this.init_socrll();

        setTimeout(() => {
          if (msg.sendSuccess === 0) {
            msg.sendSuccess = 2;
          }
        }, 5000);
      });
    },
    closeChat() {
      if (this.chat) {
        this.chat.closeSocket();
      }
    },
    init_socrll() {
      const chatBox = this.$refs.chat_content;
      // 设置滚动的距离
      if (chatBox) {
        chatBox.scrollTop = chatBox.scrollHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat_warpper {
  padding: 30px;
  padding-bottom: 126px;
}
.chat_box {
  height: 100%;
}
.chat_none {
  padding: 20px;
  color: $font-color-primary;
  line-height: 22px;
  display: flex;
  justify-content: center;
  // align-items: center;
}

.chat_wrapper {
  height: 100%;
  padding-bottom: 48px;
  box-sizing: border-box;
}
.chat_content {
  height: 100%;
  padding: 10px 16px;
  overflow-y: scroll;
}
.input_wrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;

  // padding: 11.5px 14.5px;
  background-color: $color-white;
  align-items: center;
  padding-right: 10px;
}
.reply_input {
  flex: 1;
  font-size: 16px;
  // line-height: 16px;
  // height: 48px;
  line-height: 1;
  // lineHeight:normal;
  box-sizing: border-box;
  border: 0;
  // height: 100%;
  // align-items: center;
  padding: 10px 20px;
}
.btn_reply {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("~@/assets/images/icon-send.png") no-repeat;
  background-size: 100%;
}
.btn_reply_inputed {
  background: url("~@/assets/images/icon-send-inputed.png") no-repeat;
  background-size: 100%;
}
.news_item {
  display: inline-block;
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 2px 6px 6px 9px;
}

.user_info {
  margin-bottom: 10px;
  font-size: 12px;
  color: $font-color-secondary;
}
.user_reply {
  line-height: 22px;
}
.role_people {
  .news_item {
    background-color: $background-chat-people;
  }
}
.role_anchor {
  .news_item {
    background-color: $background-chat-anchor;
  }
}
.role_me {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .news_item {
    background-color: $background-chat-me;
    color: $color-white;
    border-radius: 10px 2px 10px 10px;
  }
  .user_info {
    color: $color-white;
  }

  .msg_status {
    margin-right: 20px;
  }
}
</style>
